<template>
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <el-select v-model="status" placeholder="发货状态" clearable @change="onCurrentPage()">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入发货单号" suffix-icon="el-icon-search" v-model="deliveryNum" clearable
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商超名称" suffix-icon="el-icon-search" v-model="shopName" clearable
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商品名称" suffix-icon="el-icon-search" v-model="productName" clearable
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商品条形码" suffix-icon="el-icon-search" v-model="barCode" clearable
          @change="onCurrentPage()"></el-input>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="deliveryNum" label="发货单号" show-overflow-tooltip min-width="140" align="center">
          </el-table-column>
          <el-table-column prop="city" label="地区" show-overflow-tooltip min-width="80" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="校区" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="shopName" label="商超名称" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip min-width="130" align="center">
          </el-table-column> -->
          <el-table-column prop="categoryName" label="商品分类" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="180" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="80" align="center">
          </el-table-column>
          <el-table-column prop="factoryName" label="生产厂家" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column label="发货状态" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div>
                {{ purchasingStatus[scope.row.status] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="deliveryCount" label="发货数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="receiveCount" label="收货数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="contactMan" label="商超联系人" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="contactTel" label="联系人电话号码" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <el-table-column prop="productDate" label="生产日期" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="expirationDay" label="保质期(天)" fixed="right" show-overflow-tooltip min-width="100"
            align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      purchasingStatus: {
        2: '已发货',
        3: '已完成'
      },
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 状态
      selectData: [{
        value: '2',
        label: '已发货'
      }, {
        value: '3',
        label: '已完成'
      }
      ],
      status: '',//状态
      deliveryNum: '',//发货单号
      shopName: '',//商超名称
      productName: '',//商品名称
      barCode: '',//商品条形码
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendDeliveryProductList, {
        params: {
          status: this.status || null, //状态：2已发货，3已完成
          deliveryNum: this.deliveryNum || null,//发货单编号
          shopName: this.shopName || null,//商超名称
          productName: this.productName || null,//商品名称
          barCode: this.barCode || null,//商品名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },


    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest()
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }

    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
